import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import ParteDiario from './ParteDiario';
import ProtectedRoute from './ProtectedRoute';
import BeneficiarioForm from "./Beneficiario";
import MenuBar from "./MenuBar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {teal} from "@mui/material/colors";
import Intervencion from "./Intervencion";
import ListPage from "./ListPage";
import Footer from "./Footer";
import ReporteBeneficiario from "./ReporteBeneficiario";
import ReporteIntervencion from "./ReporteIntervencion";
import Usuario from "./Usuario";


function App() {

    const darkTheme = createTheme({
        palette: {
            primary: {
                main: teal[500],
            },
            secondary: {
                main: '#00bfa5',
            },
        },
    });

    return (


        <Router>
            <ThemeProvider theme={darkTheme}>
            <MenuBar/>
            <Routes>
                <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/partediario" element={<ProtectedRoute><ParteDiario /></ProtectedRoute>} />
                <Route path="/intervencion" element={<ProtectedRoute><Intervencion /></ProtectedRoute>} />
                <Route path="/beneficiario" element={<ProtectedRoute><BeneficiarioForm/></ProtectedRoute>} />
                <Route path="/beneficiarios" element={<ProtectedRoute><ListPage title="Sujetos de derechos"/></ProtectedRoute>} />
                <Route path="/intervenciones" element={<ProtectedRoute><ListPage title="Intervenciones" schema={["date", "ambito", "derechos", "registro", "beneficiarios", "actividad", "createdBy"]}/></ProtectedRoute>} />
                <Route path="/partediarios" element={<ProtectedRoute><ListPage title="Parte Diarios" schema={["date", "ambito", "convivencia", "turno", "eventos", "beneficiarios", "actividades", "createdBy", "turnoCompleto"]}/></ProtectedRoute>} />
                <Route path="/reportes/intervencion" element={<ProtectedRoute><ReporteIntervencion/></ProtectedRoute>} />
                <Route path="/reportes/beneficiario" element={<ProtectedRoute><ReporteBeneficiario/></ProtectedRoute>} />
                <Route path="/usuario" element={<ProtectedRoute><Usuario/></ProtectedRoute>} />
            </Routes>
            <Footer/>
            </ThemeProvider>
        </Router>


    );
}

export default App;
