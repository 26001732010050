import React, {useContext, useState} from 'react';
import {TextField, Button, Box, Grid, Typography, Alert} from '@mui/material';
import {GlobalContext} from "./Context";
import MenuItem from "@mui/material/MenuItem";
import {callEndpoint, getEndpoint} from "./Utils";
import {generos} from "./Constants";

function BeneficiarioForm() {
    const { token } = useContext(GlobalContext);
    const [responseMsg, setResponseMsg] = useState('');
    const [formValues, setFormValues] = useState({
        name: '',
        documento: '',
        telefono:'',
        email: '',
        address: '',
        sipi: '',
        fechaNacimiento: '',
        genero: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await callEndpoint('POST', getEndpoint('Beneficiarios'), null, formValues, token);
            if (response.status === 200 || response.status === 201) {
                setResponseMsg('OK');
            }
        } catch (error) {
            console.error('Error al enviar el formulario', error);
            setResponseMsg('ERROR');
            // Aquí puedes manejar el error y mostrar un mensaje al usuario
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <Box component="form"
             onSubmit={handleSubmit}
             sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 800, margin: 'auto', paddingBottom: 15}}
        >
            {responseMsg==='OK' && <Alert variant="outlined" severity="success">
                Sujeto de derecho creado con éxito
            </Alert>}
            {responseMsg==='ERROR' && <Alert variant="outlined" severity="error">
                Error creando Sujeto de derecho
            </Alert>}
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, margin: 4 }}>
                Crear sujeto de derecho
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        name="name"
                        label="Nombre"
                        value={formValues.name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="documento"
                        name="documento"
                        label="Documento"
                        value={formValues.documento}
                        onChange={handleInputChange}
                        fullWidth
                        inputProps={{ maxLength: 8, pattern: "[0-9]{8}" }}
                        helperText="Debe contener exactamente 8 dígitos"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Genero"
                        name="genero"
                        select
                        onChange={handleInputChange}
                        required
                        value={formValues.genero}
                        fullWidth
                    >
                        {generos.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="telefono"
                        name="telefono"
                        label="Teléfono"
                        value={formValues.telefono}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="sipi"
                        name="sipi"
                        label="SIPI"
                        type={"number"}
                        value={formValues.sipi}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="nacimiento"
                        name="fechaNacimiento"
                        label="Fecha de Nacimiento"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={formValues.fechaNacimiento}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="direccion"
                        name="address"
                        label="Dirección"
                        value={formValues.address}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <Button variant="contained" size="medium" color="primary" type="submit" fullWidth>
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BeneficiarioForm;
