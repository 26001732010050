import Paper from "@mui/material/Paper";
import {Autocomplete, Box, Button, Grid, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {callEndpoint, getEndpoint, exportToPDF} from "./Utils";
import {GlobalContext} from "./Context";


function ReporteIntervencion(){
    const { token } = useContext(GlobalContext);
    const [intervenciones, setIntervenciones] = useState([]);
    const [intervencionesSelected, setIntervencionesSelected] = useState([]);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [beneficiarioSelected, setBeneficiarioSelected] = React.useState('');
    const [desde, setDesde] = React.useState(null);
    const [hasta, setHasta] = React.useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await callEndpoint('GET', getEndpoint('Intervenciones'), null, null, token);
                if (response.status === 200) {
                    setIntervenciones(response.data);
                    setIntervencionesSelected(response.data);
                }
            } catch (error) {
                console.error('Error al obtener los beneficiarios', error);
            }

            //GET BENEFICIARIOS
            const responseBeneficiario = await callEndpoint('GET', getEndpoint('Beneficiarios'), null, null, token);

            if (responseBeneficiario.status === 200) {
                const data = responseBeneficiario.data.map(beneficiario => ({
                    id: beneficiario.id,
                    name: beneficiario.name,
                    documento: beneficiario.documento,
                    telefono: beneficiario.telefono,
                    email: beneficiario.email,
                    address: beneficiario.address
                }));
                setBeneficiarios(data);

            }
        }
        if (intervenciones.length === 0 || beneficiarios.length === 0) {
            fetchData();
        } else {
            let intervencionFiltered = intervenciones;
            if (beneficiarioSelected){
                intervencionFiltered = intervencionFiltered.filter(intervencion => {return intervencion.beneficiarios.map(i=> i.id).includes(beneficiarioSelected.id)});
            }
            if (desde){
                intervencionFiltered = intervencionFiltered.filter(intervencion => {return intervencion.date >= desde});
            }
            if (hasta){
                intervencionFiltered = intervencionFiltered.filter(intervencion => {return intervencion.date <= hasta});
            }
            setIntervencionesSelected(intervencionFiltered);
        }
    }, [token, beneficiarioSelected, desde, hasta, intervenciones, beneficiarios]);

    const formatValue = (value) => {
        let returnValue;
        returnValue = '';
        if (value){
            if (Array.isArray(value) && value.length > 0){
                let names = value.reduce((acc, value) => {
                    return acc + value.name + ', ';
                }, '');
                returnValue = names.slice(0, -2);
            }else if (typeof value === 'object'){
                returnValue = value.name;
            } else {
                returnValue = value;
            }
        }
        return returnValue;
    };


    return (
        <Box id="export_box" sx={{paddingBottom: 15, margin: 2}}>
            <Grid container margin={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="div" marginBottom={4}>
                        Reporte de Intervenciones
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="Desde"
                        name="date"
                        type="date"
                        size="small"
                        sx={{ width: '150px' }}
                        value={desde || ""}
                        onChange={(e) => setDesde(e.target.value)}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="Hasta"
                        name="date"
                        type="date"
                        size="small"
                        sx={{ width: '150px' }}
                        value={hasta || ""}
                        onChange={(e) => setHasta(e.target.value)}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button id="clean" variant="contained" color="primary" onClick={() => {setDesde(""); setHasta(""); setBeneficiarioSelected(null)}}>
                        Limpiar Filtros
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button id="export" variant="contained" color="primary" onClick={() => exportToPDF('export_box', 'reporte_intervencion', ['clean', 'export'])}>
                        Exportar a PDF
                    </Button>
                </Grid>
                <Grid item xs={6} marginTop={2}>
                    <Autocomplete
                        options={beneficiarios}
                        getOptionLabel={(option) => ((option && option.name)?option.name:"")}
                        id="controlled-demo"
                        value={beneficiarioSelected || null}
                        onChange={(event, newValue) => {
                            setBeneficiarioSelected(newValue);
                        }}
                        isOptionEqualToValue = {(option, value) => (option.id === value.id)}
                        renderInput={(params) => (
                            <TextField {...params} label="Beneficiario" variant="standard" />
                        )}
                    />
                </Grid>

            </Grid>

            <Box id="intervencionesBox">
                {intervencionesSelected.map((row) => (
                    <Paper key={row.id} elevation={3} sx={{margin: 2, padding: 2, border: "1px solid #000"}}>
                        <Typography variant="h6">{row.date}</Typography>
                        <Typography>Ambito: {row.ambito}</Typography>
                        <Typography>Actividad: {row.actividad}</Typography>
                        <Typography>Registro: {row.registro}</Typography>
                        <Typography>Derechos: {formatValue(row.derechos)}</Typography>
                        <Typography>Beneficiarios: {formatValue(row.beneficiarios)}</Typography>
                        <Typography>Tecnicos: {formatValue(row.usuarios)}</Typography>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
}

export default ReporteIntervencion;
