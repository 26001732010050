import React, {createContext, useEffect, useState} from 'react';

// Crear el contexto
export const GlobalContext = createContext('global');

// Crear el proveedor del contexto
export const GlobalProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        return JSON.parse(localStorage.getItem('user'));
    });

    const [token, setToken] = useState(() => {
        return localStorage.getItem('authToken');
    });

    const pagesMap = new Map([
        ['Home', { nav: '/', role: ['all'] }],
        ['Parte Diario', { nav: '/partediario', role: ['educador', 'admin'] }],
        ['Intervencion', { nav: '/intervencion', role: ['tecnico', 'admin'] }],
        ['Beneficiario', { nav: '/beneficiario', role: ['admin'] }],
        ['Beneficiarios', { nav: '/beneficiarios', role: ['admin'] }],
        ['Sujetos de Derechos', { nav: '/beneficiarios', role: ['admin'] }],
        ['Intervenciones', { nav: '/intervenciones', role: ['tecnico', 'admin'] }],
        ['Reportes Intervencion', { nav: '/reportes/intervencion', role: ['tecnico', 'admin'] }],
        ['Reportes Beneficiarios', { nav: '/reportes/beneficiarios', role: ['tecnico', 'admin'] }],
        ['Parte Diarios', { nav: '/partediarios', role: ['educador', 'tecnico', 'admin'] }],
        ['Usuario', { nav: '/usuario', role: ['admin'] }],
    ]);

    useEffect(() => {
        // Recuperar el token y el usuario del almacenamiento local al montar el componente
        const localToken = localStorage.getItem('authToken');
        const localUser = localStorage.getItem('user');
        if (localToken && localUser) {
            setUser(JSON.parse(localUser));
            setToken(localToken);
        }
    }, []);

    // Función para iniciar sesión
    const login = (user, newToken) => {
        setUser(user);
        setToken(newToken);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('authToken', token);
    };

    // Función para cerrar sesión
    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
    };

    const hasPermissionOnPage = (page) => {
        if (user && user.roles) {
            const pageConf = pagesMap.get(page);
            if (pageConf === undefined) {
                return false;
            }
            const role = pageConf.role;
            if (role.includes('all')) {
                return true;
            }
            for (let i = 0; i < role.length; i++) {
                if (user.roles.map((iter) => iter.name).includes(role[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <GlobalContext.Provider value={{ user, token, login, logout, hasPermissionOnPage}}>
            {children}
        </GlobalContext.Provider>
    );
};
