import { Box } from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "./Context";

function Footer() {
    const { user } = useContext(GlobalContext);
    return (
        <Box component="section" sx={{
            bgcolor: 'primary.main', // Establece el color de fondo igual al AppBar
            color: 'white', // Establece el color del texto a blanco
            boxShadow: 1, // Aplica una sombra similar a la del AppBar
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Centra verticalmente
            height: '30px', // Asegúrate de que el Box tenga una altura para que el centrado tenga efecto
            p: 2,
            position: 'fixed', // Posicionamiento fijo
            left: 0,
            right: 0,
            bottom: 0
        }}>
            {user && <p>Bienvenido, {user.name}</p>}
        </Box>
    )
}

export default Footer;
