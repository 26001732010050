import Paper from "@mui/material/Paper";
import {Box} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {callEndpoint, getEndpoint, exportToPDF} from "./Utils";
import {GlobalContext} from "./Context";
import Button from "@mui/material/Button";



function ReporteBeneficiario(prop){
    const { token } = useContext(GlobalContext);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await callEndpoint('GET', getEndpoint("Beneficiarios"), null, null, token);
                if (response.status === 200) {
                    setRows(response.data);
                }
            } catch (error) {
                console.error('Error al obtener los sujeto de derecho', error);
            }
        }

        fetchData();
    }, [token]);





    return (
        <Box>
            <Typography variant="h4" component="div" sx={{flexGrow: 1, margin: 4}}>
                Reportes sujetos de derecho
            </Typography>
            <Button onClick={() => exportToPDF('export_box', 'Sujeto de derecho')} sx={{padding: 3}}>Exportar a PDF</Button>
            <Box id="export_box">
                {rows.map((row) => (
                    <Paper key={row.id} elevation={3} sx={{margin: 2, padding: 2, border: "1px solid #000"}}>
                        <Typography variant="h6">{row.name}</Typography>
                        <Typography>Documento: {row.documento}</Typography>
                        <Typography>Teléfono: {row.telefono}</Typography>
                        <Typography>Email: {row.email}</Typography>
                        <Typography>Dirección: {row.direccion}</Typography>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
}

export default ReporteBeneficiario;
