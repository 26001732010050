import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import {GlobalContext} from "./Context";
import {Autocomplete, Divider, Snackbar} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import {callEndpoint, getEndpoint} from "./Utils";
import {ambitos} from "./Constants";

const Intervencion = () => {

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

    const initIntervencion =
        {
            date: formattedDate,
            ambito: 'Institucional',
            derechos:[],
            registro: '',
            beneficiarios: [],
            actividad: '',
        };


    const { user, token } = useContext(GlobalContext);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [usuariosSelected, setUsuariosSelected] = useState([]);
    const [beneficiariosSelected, setBeneficiariosSelected] = useState([]);
    const [derechosSelected, setDerechosSelected] = useState([]);
    const [derechos, setDerechos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [formValues, setFormValues] = useState(initIntervencion);

    useEffect(() => {
        async function fetchData() {
            try {
                //GET BENEFICIARIOS
                const responseBeneficiario = await callEndpoint('GET', getEndpoint('Beneficiarios'), null, null, token);

                if (responseBeneficiario.status === 200) {
                    const data = responseBeneficiario.data.map(beneficiario => ({
                        id: beneficiario.id,
                        name: beneficiario.name,
                        documento: beneficiario.documento,
                        telefono: beneficiario.telefono,
                        email: beneficiario.email,
                        address: beneficiario.address
                    }));
                    setBeneficiarios(data);

                }

                //GET Usuarios
                const responseUsuarios = await callEndpoint('GET', getEndpoint('Usuarios'), null, null, token);

                if (responseUsuarios.status === 200) {
                    const data = responseUsuarios.data.map(usuario => ({
                        id: usuario.id,
                        name: usuario.name,
                        documento: usuario.documento,
                        telefono: usuario.telefono,
                        email: usuario.email,
                        address: usuario.address
                    }));
                    setUsuarios(data);
                    setUsuariosSelected([]);
                }

                //GET actividades
                const responseActividad = await callEndpoint('GET', getEndpoint('Actividades'), null, null, token);
                if (responseActividad.status === 200) {
                    const data = responseActividad.data.map(actividad => ({
                        id: actividad.id,
                        name: actividad.name
                    }));
                    setActividades(data.sort((a, b) => a.name.localeCompare(b.name)));
                }

                // GET Derechos
                const responseDerechos = await callEndpoint('GET', getEndpoint('Derechos'), null, null, token);
                if (responseDerechos.status === 200) {
                    const data = responseDerechos.data.map(derecho => ({
                        id: derecho.id,
                        name: derecho.name
                    }));
                    setDerechos(data.sort((a, b) => a.name.localeCompare(b.name)));
                }
            } catch (error) {
                console.error('Error al obtener data del servidor', error);
            }
        }
        fetchData();
    }, [user, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let intervencionId = null;
            formValues.beneficiarios = beneficiariosSelected
            formValues.derechos = derechosSelected
            //formValues.derechos = formValues.derechosId.map(derechoId => ({ id: derechoId }));

            const responseIntervencion = await callEndpoint('POST', 'formularios/intervencion', null, formValues, token);

            if (responseIntervencion.status === 201) {
                setFormValues(initIntervencion);
                setUsuariosSelected([]);
                setBeneficiariosSelected([]);
                setDerechosSelected([]);
                setSelectedFile(null);
                intervencionId = responseIntervencion.data.id;
                setSuccessMessage('Intervención creada con éxito!');
                setOpenSnackbar(true);
            }
            //UploadFile
            if (intervencionId !== null && selectedFile !== null) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('intervencionId', intervencionId);
                const response = await callEndpoint('POST', 'formularios/intervencion/archivo', {'Content-Type': 'multipart/form-data'}, formData, token);

                if (response.status === 201) {
                    console.log('Archivo subido con éxito');
                } else {
                    console.error('Error al subir el archivo');
                }
            }
        } catch (error) {
            console.error('Error al enviar el formulario', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleFileUpload = async (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 800, margin: 'auto', paddingBottom: 15}}
            onSubmit={handleSubmit}
        >
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, margin: 4 }}>
                Formulario para intervenciones
            </Typography>
            <TextField
                label="Fecha"
                name="date"
                type="date"
                size="small"
                sx={{ width: '150px' }}
                value={formValues.date}
                onChange={handleInputChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Autocomplete
                multiple
                id="beneficiarios-autocomplete"
                options={[{ name: 'Seleccionar todos', id: 'all' }, ...beneficiarios]}
                value={beneficiariosSelected}
                isOptionEqualToValue = {(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    if (newValue && newValue.find(value => value.id === 'all')) {
                        setBeneficiariosSelected(beneficiarios);
                    } else {
                        setBeneficiariosSelected(newValue);
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <React.Fragment key={"beneficiario" + option.id}>
                        <li {...props} key={"beneficiario" + option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.id === 'all' ? <em>{option.name}</em> : option.name}
                        </li>
                        {option.id === 'all' && <Divider />}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Sujeto de Derecho" />
                )}
            />


            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={[{ name: 'Seleccionar todos', id: 'all' }, ...usuarios]}
                value={usuariosSelected}
                isOptionEqualToValue = {(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    if (newValue && newValue.find(value => value.id === 'all')) {
                        setUsuariosSelected(usuarios);
                    } else {
                        setUsuariosSelected(newValue);
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <React.Fragment key={"usuarios" + option.id}>
                    <li {...props} key={"usuarios" + option.id}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.id === 'all' ? <em>{option.name}</em> : option.name}
                    </li>
                    {option.id === 'all' && <Divider />}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Tecnicos" />
                )}
            />
            <TextField
                label="Ámbito"
                name="ambito"
                select
                value={formValues.ambito}
                onChange={handleInputChange}
                required
                fullWidth
            >
                {ambitos.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Actividad"
                name="actividad"
                select
                value={formValues.actividad}
                onChange={handleInputChange}
                required
            >
                {actividades.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
            <Autocomplete
                multiple
                id="derechos-autocomplete"
                options={[{ name: 'Seleccionar todos', id: 'all' }, ...derechos]}
                value={derechosSelected}
                isOptionEqualToValue = {(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    if (newValue && newValue.find(value => value.id === 'all')) {
                        setDerechosSelected(derechos);
                    } else {
                        setDerechosSelected(newValue);
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <React.Fragment key={"derechos" + option.id}>
                        <li {...props} key={"derechos" + option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.id === 'all' ? <em>{option.name}</em> : option.name}
                        </li>
                        {option.id === 'all' && <Divider />}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Derechos" />
                )}
            />

            <TextField
                label="Registro"
                name="registro"
                multiline
                rows={4}
                value={formValues.registro}
                onChange={handleInputChange}
                required
            />
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={handleFileUpload}
            >
                Upload file
                <VisuallyHiddenInput type="file" />
            </Button>
            <Button variant="contained" color="primary" type="submit">
                Enviar
            </Button>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={successMessage}
            />
        </Box>
    );
};

export default Intervencion;
