import React, {useContext, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './Context';
import {Alert} from "@mui/material";
import {encode as btoa} from "base-64";
import {callEndpoint, getEndpoint} from "./Utils";

function LoginPage() {
  const { login } = useContext(GlobalContext);

  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = btoa(`${formValues.username}:${formValues.password}`);
      const response = await callEndpoint('POST',getEndpoint('Login'), { 'Authorization': `Basic ${token}`}, formValues, token);
      if (response.status === 200) {
        login(response.data, token); // Usar el token devuelto en response.data.token
        navigate('/'); // Redirigir a la página de inicio
      }
    } catch (error) {
      setError('Error de autenticación. Por favor, revisa tus credenciales.');
    }
  };

  return (
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: 'auto', marginTop: 8 }}>
        {error!=='' && <Alert variant="outlined" severity="error">
          Error de autenticación. Por favor, revisa tus credenciales.
        </Alert>}
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            onSubmit={handleSubmit}
        >
          <Typography variant="h6" component="div" sx={{ marginBottom: 2, textAlign: 'center' }}>
            Inicio de Sesión
          </Typography>
          <TextField
              label="Usuario"
              name="username"
              value={formValues.username}
              onChange={handleInputChange}
              required
          />
          <TextField
              label="Contraseña"
              name="password"
              type="password"
              value={formValues.password}
              onChange={handleInputChange}
              required
          />
          <Button variant="contained" color="primary" type="submit">
            Iniciar Sesión
          </Button>
        </Box>
      </Paper>
  );
}

export default LoginPage;
