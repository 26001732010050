import React, {useContext, useEffect} from 'react';
import {GlobalContext} from './Context';
import { useNavigate } from 'react-router-dom';
import {Box, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function HomePage() {
    const { token, logout, user, hasPermissionOnPage } = useContext(GlobalContext);
    const navigate = useNavigate();

    const handleSubmitLogout = async (event) => {
        event.preventDefault();
        logout();
    };

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, user, navigate]);

    const getStyle= (page) => {
        if (hasPermissionOnPage(page)){
            return {margin: 2}
        }
        return {margin: 2, display: "none"}
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Grid container={true} spacing={2}>
                <Grid item xs={12} md={4} sx={getStyle("Intervencion")}>
                    <Paper elevation={2} sx={{
                        margin: 2,
                        padding: 2,
                        display: 'flex', // Habilita Flexbox
                        flexDirection: 'column', // Alinea los hijos verticalmente
                        alignItems: 'center', // Centra los hijos horizontalmente
                        justifyContent: 'center' // Centra los hijos verticalmente
                    }}>
                        <Typography variant="h6" component="div" sx={{margin: 2}}>
                            Intervenciones
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/intervencion")} sx={getStyle("Intervencion")} fullWidth>Alta</Button>
                        <Button variant="contained" onClick={() => navigate("/intervenciones")} sx={getStyle("Intervenciones")} fullWidth>Listado</Button>

                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={getStyle("Parte Diario")}>
                    <Paper elevation={2} sx={{
                        margin: 2,
                        padding: 2,
                        display: 'flex', // Habilita Flexbox
                        flexDirection: 'column', // Alinea los hijos verticalmente
                        alignItems: 'center', // Centra los hijos horizontalmente
                        justifyContent: 'center' // Centra los hijos verticalmente
                    }}>
                        <Typography variant="h6" component="div" sx={{margin: 2}}>
                            Partes Diarios
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/partediario")} sx={getStyle("Parte Diario")} fullWidth>Alta</Button>
                        <Button variant="contained" onClick={() => navigate("/partediarios")} sx={getStyle("Parte Diarios")} fullWidth>Listado</Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={getStyle("Beneficiario")}>
                    <Paper elevation={2} sx={{
                        margin: 2,
                        padding: 2,
                        display: 'flex', // Habilita Flexbox
                        flexDirection: 'column', // Alinea los hijos verticalmente
                        alignItems: 'center', // Centra los hijos horizontalmente
                        justifyContent: 'center' // Centra los hijos verticalmente
                    }}>
                        <Typography variant="h6" component="div" sx={{margin: 2}}>
                            Sujetos de derecho
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/beneficiario")} sx={getStyle("Beneficiario")} fullWidth>Alta</Button>
                        <Button variant="contained" onClick={() => navigate("/beneficiarios")} sx={getStyle("Beneficiarios")} fullWidth>Listado</Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={getStyle("Reportes Intervencion")}>
                    <Paper elevation={2} sx={{
                        margin: 2,
                        padding: 2,
                        display: 'flex', // Habilita Flexbox
                        flexDirection: 'column', // Alinea los hijos verticalmente
                        alignItems: 'center', // Centra los hijos horizontalmente
                        justifyContent: 'center' // Centra los hijos verticalmente
                    }}>
                        <Typography variant="h6" component="div" sx={{margin: 2}}>
                            Reportes
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/reportes/intervencion")} sx={getStyle("Reportes Intervencion")} fullWidth>Reporte intervenciones</Button>
                        <Button variant="contained" onClick={() => navigate("/reportes/beneficiario")} sx={getStyle("Reportes Beneficiarios")} fullWidth>Reporte sujeto de derecho</Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={getStyle("Usuario")}>
                    <Paper elevation={2} sx={{
                        margin: 2,
                        padding: 2,
                        display: 'flex', // Habilita Flexbox
                        flexDirection: 'column', // Alinea los hijos verticalmente
                        alignItems: 'center', // Centra los hijos horizontalmente
                        justifyContent: 'center' // Centra los hijos verticalmente
                    }}>
                        <Typography variant="h6" component="div" sx={{margin: 2}}>
                            Usuarios
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/usuario")} sx={getStyle("Usuario")} fullWidth>Alta</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box
                        component="form"
                        flexGrow={1}
                        margin={2}
                        onSubmit={handleSubmitLogout}
                    >
                        {token &&
                            <Button variant="contained" color="primary" type="submit">
                                Cerrar sesión
                            </Button>
                        }
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}

export default HomePage;
