import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import {GlobalContext} from "./Context";
import {
    Alert,
    Autocomplete,
    Divider,
    Snackbar
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {callEndpoint, getEndpoint} from "./Utils";
import { ambitos } from './Constants';


const convivencias = ['La jornada transcurre en buen clima de convivencia', 'Los conflictos impiden un normal desarrollo de la jornada', 'Se sucitan situaciones puntuales de conflicto que no impiden un desarrollo normal de la jornada'];
const turnos = ['Turno de 06 a 14 Hrs', 'Turno de 14 a 22 Hrs', 'Turno de 22 a 06 Hrs'];
const currentDate = new Date();
const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

const ParteDiario = () => {

    const initParteDiario = {
        date: formattedDate,
        ambito: 'Institucional',
        eventos: '',
        turnoCompleto: false,
        beneficiarios: [],
        actividades: [],
        convivencia: '',
        turno: ''
    };

    const { user, token } = useContext(GlobalContext);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [message, setMessage] = useState('');
    const [result, setResult] = useState('info');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [beneficiariosSelected, setBeneficiariosSelected] = useState([]);
    const [actividadesSelected, setActividadesSelected] = useState([]);
    const [formValues, setFormValues] = useState(initParteDiario);

    useEffect(() => {
        async function fetchData() {
            try {
                //GET BENEFICIARIOS
                const responseBeneficiario = await callEndpoint('GET', getEndpoint('Beneficiarios'), null, null, token);

                if (responseBeneficiario.status === 200) {
                    const data = responseBeneficiario.data.map(beneficiario => ({
                        id: beneficiario.id,
                        name: beneficiario.name,
                        documento: beneficiario.documento,
                        telefono: beneficiario.telefono,
                        email: beneficiario.email,
                        address: beneficiario.address
                    }));
                    setBeneficiarios(data);

                }
                //GET actividades
                const responseActividad = await callEndpoint('GET', getEndpoint('Actividades'), null, null, token);

                if (responseActividad.status === 200) {
                    const data = responseActividad.data.map(actividad => ({
                        id: actividad.id,
                        name: actividad.name
                    }));
                    setActividades(data);
                }
            } catch (error) {
                console.error('Error al obtener data del servidor', error);
            }
        }
        fetchData();
    }, [user, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            formValues.beneficiarios = beneficiariosSelected
            formValues.actividades = formValues.actividades.map(actividadesId => ({ id: actividadesId }));
            const response = await callEndpoint('POST', getEndpoint('Parte Diarios'), null, formValues, token);

            if (response.status === 201) {
                setFormValues(initParteDiario);
                setBeneficiariosSelected([]);
                setActividadesSelected([]);
                setMessage('Parte diario creado con éxito!');
                setResult('success');
                setOpenSnackbar(true);
            }
        } catch (error) {
            setResult('error');
            setMessage('Error al enviar el formulario');
            console.error('Error al enviar el formulario', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleInputCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 800, margin: 'auto', paddingBottom: 15 }}
            onSubmit={handleSubmit}
        >
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, margin: 4 }}>
                Formulario para parte diario
            </Typography>
            <TextField
                label="Fecha"
                name="date"
                type="date"
                size="small"
                sx={{ width: '150px' }}
                value={formValues.date}
                onChange={handleInputChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Autocomplete
                multiple
                id="beneficiarios-autocomplete"
                options={[{ name: 'Seleccionar todos', id: 'all' }, ...beneficiarios]}
                value={beneficiariosSelected}
                isOptionEqualToValue = {(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    if (newValue && newValue.find(value => value.id === 'all')) {
                        setBeneficiariosSelected(beneficiarios);
                    } else {
                        setBeneficiariosSelected(newValue);
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <React.Fragment key={"beneficiario" + option.id}>
                        <li {...props} key={"beneficiario" + option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.id === 'all' ? <em>{option.name}</em> : option.name}
                        </li>
                        {option.id === 'all' && <Divider />}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Beneficiarios" />
                )}
            />
            <TextField
                label="Convivencia"
                name="convivencia"
                select
                value={formValues.convivencia}
                onChange={handleInputChange}
                required
            >
                {convivencias.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                label="Turnos"
                name="turno"
                select
                value={formValues.turno}
                onChange={handleInputChange}
                required
                fullWidth
            >
                {turnos.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                label="Ámbito"
                name="ambito"
                select
                value={formValues.ambito}
                onChange={handleInputChange}
                required
                fullWidth
            >
                {ambitos.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            <Autocomplete
                multiple
                id="actividades-autocomplete"
                options={actividades}
                value={actividadesSelected}
                isOptionEqualToValue = {(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => { setActividadesSelected(newValue); }}
                renderOption={(props, option, { selected }) => (
                    <React.Fragment key={"actividad" + option.id}>
                        <li {...props} key={"actividad" + option.id}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Actividades" />
                )}
            />
            <TextField
                label="Eventos"
                name="eventos"
                multiline
                rows={4}
                value={formValues.eventos}
                onChange={handleInputChange}
                required
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formValues.turnoCompleto}
                        onChange={handleInputCheckbox}
                        name="turnoCompleto"
                    />
                }
                label="Turno Completo"
            />
            <Button variant="contained" color="primary" type="submit">
                Enviar
            </Button>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={result}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ParteDiario;
