import React, {useContext, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {GlobalContext} from './Context';

function ProtectedRoute({ children }) {
    const { token, user } = useContext(GlobalContext);

    useEffect(() => {
        if (token) {
            localStorage.setItem('authToken', token); // Guardar el token en localStorage
        } else {
            localStorage.removeItem('authToken');
        }
    }, [token, user]);

    if (!token) {
        return <Navigate to="/login" />;
    }
    return children;
}

export default ProtectedRoute;
