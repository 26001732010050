import React from 'react';
import './ImageComponent.css';

const ImageComponent = ({ src, alt, width, height }) => {
    return (
        <div className="image-container">
            <img src={src} alt={alt} width={width} height={height} className="responsive-image" />
        </div>
    );
}

export default ImageComponent;
